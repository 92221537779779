<!-- 
author: 张洪志
description: 基金超市
 -->
<template>
  <section class="fund-market">
    <fund-nav />
    <div class="banner-bg"></div>
    <!-- 热门 -->
    <div class="sec-top vw flex-between">
      <div class="left">
        <div class="date">({{huiJinBao.tradingDay}})</div>
        <div class="profit-rate">{{(huiJinBao.annualizedrrinsingleweek||0).toFixed(2)}}%</div>
        <router-link :to="{name:'RechargeS1',params:{code:huiJinBao.secucode}}" class="btn btn-buy">立即充值</router-link>
      </div>
      <div class="right">
        <div class="top flex-between">
          <div>
            <big>热门主题</big>
            <small class="orange">热门主题基金</small>
          </div>
          <div class="more">
            <router-link :to="{name:'TopicFund'}" class="gray">更多</router-link>
          </div>
        </div>
        <div class="flex-between">
          <router-link class="item" v-for="(item) in hotThemeFunds" :key="item.fundCode" :to="{name:item.fundStatus=='1'?'NewFundDetail':'FundDetail',params:{fundCode:item.fundCode,fundType:item.ofundType,newfund:item.fundStatus === '1' ? '1' : ''}}">
            <span class="tag btn-orange">{{item.themeName}}</span>
            <div class="gray small code">基金代码 {{item.fundCode}}</div>
            <div class="title" :title="item.fundName"><span class="name">{{ item.fundName}}</span></div>
            <div class="flex-between">
              <div>
                <div class="gray small">近一年收益</div>
                <div :class="['profit-rate',item.incomeYearNum < 0 ? 'green':'red']">
                  <span>{{ item.incomeYear }}</span>
                  <small>%</small>
                </div>
              </div>
              <div class="rate-line">
                <img src="@/static/img/fund-market/rate-line.png" alt="收益率走势">
              </div>
            </div>
            <div class="risk">
              <span :class="{'red':item.highRisk}">{{item.risklevel}}</span>
              <span>{{item.fundType}}</span>
            </div>
            <div class="gray small">
              {{item.fundManagerName}}
            </div>
            <div class="flex-between bottom">
              <div>
                <p class="gray">{{item.enddate}}</p>
                <b class="newest-value">{{item.newestValue}}</b>
                <p>
                  <span class="icon-new">new</span>
                  <span class="gray">最新净值</span>
                </p>
              </div>
              <div>
                <a @click.prevent="buyFund(item)" :class="['btn-orange btn-buy', {'disable stop-buy':item.buyStatus === '0'}]">{{item.buyTxt}}</a>
              </div>
            </div>
          </router-link>
        </div>        
      </div>
    </div>
    <!-- 分类 -->
    <div class="sec-cat">
      <div class="cat vw">
        <div>
          <span class="title orange">基金超市</span>
          <span>平台精选基金榜单，权衡利弊，择优选择</span>
        </div>
        <div class="content">
          <dl class="flex-between">
            <dt>基金类型</dt>
            <dd>
              <span :class="['btn',{'current': !fundOrderCat}]" @click="getFundSort('cat','')">全部</span>
              <span :class="['btn',{'current': fundOrderCat===item.code}]" v-for="item in fundOrderCats" :key="item.code" @click="getFundSort('cat',item.code)">
                {{ item.name }}
              </span>
            </dd>
          </dl>
          <dl class="flex-between">
            <dt>风险等级</dt>
            <dd>
              <span :class="['btn',{'current': !riskLevel}]" @click="getFundSort('risk','')">全部</span>
              <span :class="['btn',{'current': riskLevel===item.code}]" v-for="item in riskLevels" :key="item.code" @click="getFundSort('risk',item.code)">
                {{ item.name }}
              </span>
            </dd>
          </dl>
          <dl class="flex-between">
            <dt>基金公司</dt>
            <dd>
              <span :class="['btn',{'current': !fundCompany}]" @click="getFundSort('company','')">全部</span>
              <span :class="['btn',{'current': fundCompany===item.code}]" v-for="item in fundCompanys" :key="item.code" @click="getFundSort('company',item.code)">
                {{ item.name }}
              </span>
            </dd>
          </dl>
          <dl class="flex-between">
            <dt>产品分类</dt>
            <dd>
              <span :class="['btn',{'current': !productType}]" @click="getFundSort('product','')">全部</span>
              <span :class="['btn',{'current': productType===item.code}]" v-for="item in productTypes" :key="item.code" @click="getFundSort('product',item.code)">
                {{ item.name }}
              </span>
            </dd>
          </dl>
          <dl class="flex-between">
            <dt>基金主题</dt>
            <dd>
              <span :class="['btn',{'current': !fundTheme}]" @click="getFundSort('theme','')">全部</span>
              <span :class="['btn',{'current': fundTheme===item.code}]" v-for="item in fundThemes" :key="item.code" @click="getFundSort('theme',item.code)">
                {{ item.name }}
              </span>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="sec-3 fund-order vw">
      <div style="position: relative;">
        <div class="flex-between fund-order-title fund-order-list">
          <span>基金代码</span>
          <span class="name">基金名称</span>
          <span>风险等级</span>
          <span class="flex-between">
            <span>最新净值</span>
            <i :class="['btn', fundSortStyle('newestValue')]" @click="sortFund('newestValue')"></i>
          </span>
          <span class="flex-between">
            <span>日涨跌幅</span>
            <i :class="['btn', fundSortStyle('changeDay')]" @click="sortFund('changeDay')"></i>
          </span>
          <span class="flex-between">
            <span>
              近一个月<br>涨跌幅 
            </span>
            <i :class="['btn', fundSortStyle('changeMonth')]" @click="sortFund('changeMonth')"></i>
          </span>
          <span class="flex-between">
            <span>
              近三个月<br>涨跌幅 
            </span>
            <i :class="['btn', fundSortStyle('changeThreeMonth')]" @click="sortFund('changeThreeMonth')"></i>
          </span>
          <span class="flex-between">
            <span>
              近半年<br>涨跌幅 
            </span>
            <i :class="['btn', fundSortStyle('changeHalfYear')]" @click="sortFund('changeHalfYear')"></i>
          </span>
          <span class="flex-between">
            <span>
              近一年<br>涨跌幅 
            </span>
            <i :class="['btn', fundSortStyle('changeYear')]" @click="sortFund('changeYear')"></i>
          </span>
          <span v-if="false">买入费率</span>
          <span class="action">操作</span>
        </div>
        <div class="flex-between dark fund-order-content fund-order-list" v-for="item in fundOrderData" :key="item.fundCode">
          <span class="">{{ item.fundCode }}</span>
          <router-link class="name" :to="{name:item.fundStatus=='1'?'NewFundDetail':'FundDetail',params:{fundCode:item.fundCode,fundType:item.ofundType,newfund:item.fundStatus === '1' ? '1' : ''}}">{{ item.fundName}}</router-link>
          <span :class="item.ofundRisklevel >= 3 ? 'red' : item.ofundRisklevel >= 1 ? 'orange' : 'gray'">{{ item.risklevel }}</span>
          <span class="value dark">
            <span>{{ item.newestValue }}</span>
            <br>
            <span class="gray">{{ item.enddate }}</span>
          </span>
          <span :class="formatRiseStyle(item.changeDay)">{{ item.changeDay }}%</span>
          <span :class="formatRiseStyle(item.changeMonth)">{{ item.changeMonth }}%</span>
          <span :class="formatRiseStyle(item.changeThreeMonth)">{{ item.changeThreeMonth }}%</span>
          <span :class="formatRiseStyle(item.changeHalfYear)">{{ item.changeHalfYear }}%</span>
          <span :class="formatRiseStyle(item.changeYear)">{{ item.changeYear }}%</span>
          <span :class="item.saleFeeRate.indexOf('%') > -1 ? 'orange' : 'gray'" v-if="false">{{ item.saleFeeRate}}</span>
          <span class="action">
            <input type="button" :value="item.buyTxt" :class="['btn btn-orange btn-buy', {'disable stop-buy':item.buyStatus === '0'}]" @click="buyFund(item)">
          </span>
        </div>
      </div>
    </div>
  
    <div class="page-bar vw">
      <page-bar :total="pageTotal" :display="5" @click="goPage" />
    </div>
    <DinDetails :showmodel="showBuyConfirm" @cancels="showBuyConfirm=false" @showdelte="showBuyConfirm=false" @confirms="buyConfirm">
      {{buyConfirmTxt}}
    </DinDetails>
  </section>
</template>

<script>
import FundNav from '@/views/fund-market/components/FundNav.vue'
import DinDetails from '@/components/Models.vue'
import PageBar from '@/components/Pagebar.vue'
import {getDicts} from '@/api/dict.js'
import {highest7,themeList,fundList,themeOrProduct,fundCompany} from '@/api/fund.js'
export default {
  name: 'FundMarket',
  components:{
    FundNav,
    PageBar,
    DinDetails
  },
  data() {
    return {
      huiJinBao:{},
      
      hotThemeFunds:[],
      
      fundOrderCats: [],
      fundOrderCat: '',
      fundOrderType: {},
      fundOrderData: [],
      riskLevels: [],
      riskLevel: '',
      fundCompanys: [],
      fundCompany: '',
      productTypes: [],
      productType: '',
      fundThemes:[],
      fundTheme:'',
      pageTotal: 0,
      pageNum: 1,
      
      showBuyConfirm: false,
      buyConfirmTxt: '',
      buyConfirmStep: 0,
      preBuyFund:null
    }
  },
  created() {
    this.searchKey = this.$route.params.searchKey || ''
    this.highest7()
    this.getFundType()
    this.getProductType()
    this.getFundTheme()
    this.getFundCompany()
    this.getRiskLevel().then(() => {
      this.getHotThemeFunds()
      this.sortFund('changeDay')
    })
  },
  computed: {
    fundSearchKey() {
      return this.$store.state.fundSearchKey 
    }
  },
  watch: {
    fundSearchKey(newValue) {
      this.searchKey = newValue
      this.getFundSort()
    }
  },
  methods: {
    /** 惠金宝 */
    highest7() {
      highest7().then(result => {
        this.huiJinBao = result.data ||{}
      })
    },
    /** 获取热门主题基金 */
    getHotThemeFunds() {
      themeList(1,3,'1','1','1').then(result => {
        let themes = result.data.data
        themes.forEach(theme => {
          fundList({
            pageSize: 1,
            pageNum: 1,
            customerAppTheme: theme.themeId,
            changeYear: -1,
            privateFlag: '0',
            customerAppThemeTop: '1'
          }).then(result2 => {
            let funds = result2.data.data
            if(funds.length === 1) {
              let fund = funds[0]
              fund.themeName = theme.themeName
              fund.risklevel = (this.riskLevels.find(n => fund.ofundRisklevel === n.code)||{}).name
              fund.highRisk = fund.risklevel.indexOf('高') > -1
              fund.fundType = (this.fundOrderCats.find(n => fund.ofundType === n.code)||{}).name
              fund.ofundRisklevel = parseInt(fund.ofundRisklevel)
              fund.newestValue = fund.newestValue.$trimZero()
              fund.incomeYear = fund.incomeYear.$trimZero(2)
              fund.incomeYearNum = parseFloat(fund.incomeYear)
              fund.buyTxt = fund.fundStatus === '1' ? '认购' : '买入'
              fund.newfund = fund.fundStatus === '1' ? '1' : ''
              this.hotThemeFunds.push(fund)
            }
          })
        })
      })
    },    
		/** 获取基金类型 */
		getFundType() {
			getDicts('sys_fund_type').then(result => {
				if(!result.data) {
					return
				}
				this.fundOrderCats = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
			})
		},
    /** 获取基金风险级别 */
    getRiskLevel() {
      return getDicts('sys_fund_risklevel').then(result => {
        if(!result.data) {
          return
        }
        this.riskLevels = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
      })
    },
    /** 获取产品类型 */
    getProductType() {
      themeOrProduct('3','2').then(result => {
        if(!result.data) {
          return
        }
        this.productTypes = Array.from(result.data, m => ({name:m.themeName,code:m.themeId}))
      })
    },
    /** 获取基金类型 */
    getFundTheme() {
      themeOrProduct('1','2').then(result => {
        if(!result.data) {
          return
        }
        this.fundThemes = Array.from(result.data, m => ({name:m.themeName,code:m.themeId}))
      })
    },
    /** 获取基金公司列表 */
    getFundCompany() {
      fundCompany(10,1).then(result => {
        if(!result.data) {
          return
        }
        this.fundCompanys = Array.from(result.data.fundCompanyList, m => ({name:(m.investadvisorname||'').replace(/(管理)?(股份)?有限公司$/,''),code:m.investadvisorcode}))
      })
    },
    /** 基金排序箭头样式 */
    fundSortStyle(key) {
      return this.fundOrderType[key] === '-1' ? 'ico-sort-down' : this.fundOrderType[key] === '1' ? 'ico-sort-up' : 'ico-sort'
    },
    /** 基金排序 */
    sortFund(key) {
      let order = this.fundOrderType[key]
      order = !order ? '-1' : order === '-1' ? '1' : ''
      this.fundOrderType = {}
      this.fundOrderType[key] = order
      this.getFundSort()
    },
    /** 获取基金排行 */
    getFundSort(key,value) {
      let params = {
        pageSize:6,
        pageNum:this.pageNum,
        privateFlag: '0'
      }
      if(key === 'cat') {
        this.fundOrderCat = value
      }
      if(key === 'risk') {
        this.riskLevel = value
      }
      if(key === 'company') {
        this.fundCompany = value
      }
      if(key === 'product') {
        this.productType = value
      }
      if(key === 'theme') {
        this.fundTheme = value
      }
      params.ofundType = this.fundOrderCat
      params.ofundRisklevel = this.riskLevel
      params.fundManagerCode = this.fundCompany
      params.customerAppClassifyTheme = this.productType
      params.customerAppTheme = this.fundTheme
      
      let searchKey = this.searchKey.trim()
      if(!searchKey && searchKey !== 0) {
        searchKey = ''
      } else {
        if(/^\d{6}$/.test(searchKey)) {
          params.fundCode = searchKey
        } else {
          params.fundName = searchKey
        }
      }
      
      let _params = {...params,...this.fundOrderType}
      fundList(_params).then(result => {
        let data = result.data.data
        data.forEach(m => {
          m.risklevel = ((this.riskLevels.find(n => m.ofundRisklevel === n.code)||{}).name||'').replace('风险','')
          m.ofundRisklevel = parseInt(m.ofundRisklevel)
          m.newestValue = m.newestValue.$trimZero()
          m.changeDay = (parseFloat(m.changeDay)>0?'+':'')+m.changeDay.$trimZero(2)
          m.changeMonth = (parseFloat(m.changeMonth)>0?'+':'')+m.changeMonth.$trimZero(2)
          m.changeThreeMonth = (parseFloat(m.changeThreeMonth)>0?'+':'')+m.changeThreeMonth.$trimZero(2)
          m.changeHalfYear = (parseFloat(m.changeHalfYear)>0?'+':'')+m.changeHalfYear.$trimZero(2)
          m.changeYear = (parseFloat(m.changeYear)>0?'+':'')+m.changeYear.$trimZero(2)
          m.saleFeeRate = m.saleFeeRate ? m.saleFeeRate + '%' : '免手续费'
          m.buyTxt = m.fundStatus === '1' ? '认购' : '买入'
          m.newfund = m.fundStatus === '1' ? '1' : ''
        })
        this.fundOrderData = data
        this.pageTotal = result.data.pages
      })
    },
    /** 格式化基金涨跌样式
     * @param {Number|String} priceRate 涨跌幅
     */
    formatRiseStyle(priceRate) {
      priceRate = priceRate || 0
      priceRate = parseFloat(priceRate)
      return priceRate > 0 ? 'red' : priceRate < 0 ? 'green' : ''
    },
		/** 购买基金 */
    buyFund(fund) {
      if(fund.buyStatus === '0') {
        return;
      }
      this.buyConfirmStep = 0
      let token = this.$store.getters.token;
      let _userInfo = localStorage.getItem("customerInfo");
      if (!token || !_userInfo) {
        this.buyConfirmTxt = '请登录!'
        this.buyConfirmStep = 1
      }else {
        let userInfo = JSON.parse(_userInfo);
        if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
          this.buyConfirmTxt = '请先开户!'
          this.buyConfirmStep = 2
        }else if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
          this.buyConfirmTxt = '请补充资料!'
          this.buyConfirmStep = 3
        }else if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
          this.buyConfirmTxt = '请设置交易密码!'
          this.buyConfirmStep = 4
        }else if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
          this.buyConfirmTxt = '请先完成风险测评!'
          this.buyConfirmStep = 5
        }else if(userInfo.riskLevel < fund.ofundRisklevel){
          this.buyConfirmTxt = '当前产品风险等级高于您的风险测评等级，确认购买？'
          this.buyConfirmStep = 6
        }
      }
      this.preBuyFund = fund
      if(!this.buyConfirmStep) {
        this.buyConfirm()
        return
      }      
      this.showBuyConfirm = true
    },
    /** 购买基金确认 */
    buyConfirm() {
      this.showBuyConfirm = false
      if(this.buyConfirmStep === 1) { 
        this.$router.push({ name: "Login" })
        return
      }
      if(this.buyConfirmStep === 2) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 3) { 
        this.$router.push({ name: "PersonalDataSupply" });
        return
      }
      if(this.buyConfirmStep === 4) { 
        this.$router.push({ name: "OpenAccount" });
        return
      }
      if(this.buyConfirmStep === 5) { 
        this.$router.push({ name: "RiskAssessment" });
        return
      }
      this.$router.push({name:'ProcessPurchase',params:{fundCode:this.preBuyFund.fundCode,newfund:this.preBuyFund.newfund}})
    },
    /** 页跳转 */
    goPage(page) {
      this.pageNum = page
      this.getFundSort()
    }
  }
 }
</script>

<style lang="less" scoped="scoped">
  @orange: #D2AB74;
  @gray: #bbb;
  @red: #D43F3F;
  @green:#55aa00;
  .orange{ color:@orange; }
  .gray{ color:@gray;}
  .red{color:@red;}
  .green{ color: @green;}
  .btn-orange{ color: #fff; background: @orange; background-image: linear-gradient(30deg,#E7C39C,#C08D5E);}
  .btn{border-radius: 3px;}
  
  .banner-bg{ background:#5C6E87 url(../../static/img/banner/fund-market-bg.jpg) no-repeat center; height: 425px; margin-bottom: -400px;}
  .sec-top{ background: none;
    .left{ color:#fff;background: #71351d url(../../static/img/fund-market/hjb.jpg); width: 240px; padding:36px 24px; text-align: center; font-weight: bold;
      .title{font-size:42px; letter-spacing: 2px; padding-top:5px;}
      .summary{font-size: 18px; line-height: 1.6em; padding:1em 0;}
      .profit-txt{font-size: 18px; padding-top: 0.5em;}
      .profit-rate{ font-size: 50px; color:#900009;}
      .date{color: #900009; margin-top:160px;}
      .btn-buy{ color:#C15527; background: #FEC655;background-image: linear-gradient(
0deg,#FEC655, #FFE7C1); display: block; border-radius: 2em; font-size: 20px; font-weight: normal; padding:10px; width: 80%; margin:auto; margin-top:100px;}
    }
    .right{ width: 860px; padding: 1em; background: #fff; box-shadow: 0 0px 10px 0px #ddd;
      &>div:last-child{ justify-content: flex-start;}
      .top{ align-items: flex-end; margin-bottom: 1em;
        big{font:bold 26px "microsoft yahei";}
        small{font-size:15px; margin-left:1em;}
      }
      .item{ width:29.5%; padding:1em;box-shadow: 0 0 3px #ddd; border-radius: 10px; padding-bottom:30px; margin: 0 3px;
        &:hover{box-shadow: 0 0 8px #ddd;}
        .small{ font-size:12px;}
        .tag{padding:0.4em 2em; border-radius: 3px; display: inline-block;}
        .code{ line-height: 2.5em;}
        .title{ font:bold 22px/1em "microsoft yahei"; height:1em; overflow:hidden; margin-bottom: 10px;}
        .profit-rate{font:bold 31px "microsoft yahei"; margin-top:5px;}
        .risk{padding:1em 0;
          &>span{ border:1px solid @orange; color:@orange; padding:4px 20px; border-radius: 3px; margin:0 3px;}
          &>span.red{.red();}
        }
        .bottom{ margin-top:2em; line-height: 1.8em; font-size:15px; align-items: center;
          .newest-value{ font-size:24px; }
          .icon-new {font-size: 12px; color:#fff; background: @orange; border-radius: 2em; height: 20px; display: inline-block; line-height: 20px; margin-right: 5px; vertical-align: text-bottom;}
          .btn-buy{display: block; padding:8px 50px; border-radius: 2em; background:#EEC7A0;}
        }
      }
    }
  }
  .sec-cat{ margin-top:50px;
    .cat{ padding-top:35px;  background: #F9FAFB;
      .title{font:bold 33px "microsoft yahei"; margin-right:20px;}
      .content{ padding:2em 1em; font-size:15px; line-height: 3em;
        dt{color:@gray; font-weight: bold;}
        dd{width:1100px;
          span{ margin:0 1.5em; display: inline-block; line-height: 2em; }
          .current{background: #DEB87B; color:#fff;padding:1px 20px;}
        }
      }
    }
  }
  .fund-order{
    .fund-order-list{ text-align: center;}
    .fund-order-title{ background: #F3F3F3; padding: 1.5em 0; align-items: center;}
    .fund-order-title .btn{ font-size: 1.2em; margin: 0px 0 0 2px;}
    .fund-order-title>span{ justify-content: center; align-items: center;}
    .fund-order-list>span{ width: 7em;}
    .fund-order-list .name{ text-align: left; width:200px;}
    .fund-order-list .action {width:170px;}
    .fund-order-content { padding: 1.8em 0;  line-height: 2em; border-bottom: 1px solid #eee;
      .value{ line-height: 1.5em;}
      .dark{ color: #5d5d5d;}
      .btn-buy{ padding: 10px 48px; height:2.5em;}
    }
  }
  .page-bar{ text-align: right; background: #fff; padding:1em 0;}
</style>
